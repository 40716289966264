import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLanguageSelector, LazyMegaMenu, LazySectionBanner, LazySectionBannerColumn, LazySessionMenu, LazyMegaMenuColumn, LazyMegaMenuColumnItem, LazyAbTest, LazyAccordion, LazyAccordionGroup, LazyPhoneInputPlaceholder, LazyRichText, LazyRichTextBlok, LazyNavMobileFull, LazyNavigationMenuSection, LazyPage } from '#components'
const lazyGlobalComponents = [
  ["LanguageSelector", LazyLanguageSelector],
["MegaMenu", LazyMegaMenu],
["SectionBanner", LazySectionBanner],
["SectionBannerColumn", LazySectionBannerColumn],
["SessionMenu", LazySessionMenu],
["MegaMenuColumn", LazyMegaMenuColumn],
["MegaMenuColumnItem", LazyMegaMenuColumnItem],
["AbTest", LazyAbTest],
["Accordion", LazyAccordion],
["AccordionGroup", LazyAccordionGroup],
["PhoneInputPlaceholder", LazyPhoneInputPlaceholder],
["RichText", LazyRichText],
["RichTextBlok", LazyRichTextBlok],
["NavMobileFull", LazyNavMobileFull],
["NavigationMenuSection", LazyNavigationMenuSection],
["Page", LazyPage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
