import { useStore } from 'vuex';
import { useRuntimeConfig } from '#app';
import type { VueI18n } from 'vue-i18n';

export default defineNuxtPlugin({
  name: 'init',
  async setup(nuxtApp) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    // Bit ugly, but this should always exist on nuxtApp, same issue found here: https://github.com/nuxt-modules/i18n/issues/2955
    // Perhaps we should just not use store for that anymore
    type LocalI18n = VueI18n & {
      locale: ComputedRef<string>;
    };

    const i18n = nuxtApp.$i18n as LocalI18n;
    const headers = useRequestHeaders();
    const config = useRuntimeConfig();

    const getCountry = (): string => {
      let country: string = 'ee';

      if (import.meta.server) {
        const host: string = headers['host'];

        country = host.split('.').pop()?.split(':')[0] ?? country;

        if (!country || country.length !== 2 || !store.state.locales[country]) {
          const countryFromCookie = useCookie('rendin_demo_country');

          country = countryFromCookie.value || 'ee';
          store.dispatch('showCountrySelect');
        }

        if (
          route.query.setDemoCountry &&
          typeof route.query.setDemoCountry === 'string'
        ) {
          return route.query.setDemoCountry;
        }

        return country;
      } else {
        return store.state.country;
      }
    };

    const getCountryIsEstonia = () => getCountry() === 'ee';

    const getLocaleFromPath = (path: string): string | null => {
      const locales = store.state.locales;
      const countryCode = getCountry();

      // if root level locale
      if (path.length === 3) {
        if (locales[countryCode].list.includes(path.slice(1))) {
          return path.slice(1);
        }
      } else if (path.match(/^([/][A-Za-z]{2}[/])/g)) {
        const testLocale =
          path
            .match(/^([/][A-Za-z]{2}[/])/g)?.[0]
            .replace('/', '')
            .replace('/', '') ?? '';

        if (locales[countryCode].list.includes(testLocale)) {
          return testLocale;
        }
      }

      return null;
    };

    const getHostnameFromURL = (url: string): string | null => {
      // run against regex
      const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
      // extract hostname (will be null if no match is found)
      return matches && matches[1];
    };

    const countryCode = getCountry();
    const defaultLocale = store.state.locales[countryCode].default;

    // Server side init
    if (import.meta.server) {
      if (route.path.includes('estonia')) {
        store.dispatch('setCountry', 'ee');
      } else if (route.path.includes('poland')) {
        store.dispatch('setCountry', 'pl');
      } else {
        store.dispatch('setCountry', countryCode);
      }

      //console.log(context.query._storyblok_lang)

      if (route.query._storyblok) {
        store.dispatch('setStoryblok');

        if (route.query._storyblok_lang === 'default') {
          i18n.setLocale('en');
        } else {
          let lang;

          const path = getLocaleFromPath(route.path)
            ? route.path.substring(3)
            : route.path;

          const queryStoryblokLang = route.query._storyblok_lang?.toString();
          if (
            queryStoryblokLang &&
            store.state.locales[countryCode].list.includes(queryStoryblokLang)
          ) {
            lang = queryStoryblokLang;
          } else {
            lang = 'en';
          }

          i18n.setLocale(lang);

          router.push({ path: path });
        }
      } else {
        // Get default language from path or fallback to default locale
        const url = route.fullPath;
        const localeFromPath = getLocaleFromPath(url);
        const locale = localeFromPath || defaultLocale;

        i18n.setLocale(locale);
        // TODO - migration - decide if that's necessary
        //context.$dayjs.locale(locale);
      }
    }

    const getDefaultLocale = (): string => {
      return store.state.locales[getCountry()].default;
    };

    const getHost = (): string => {
      if (!import.meta.server) {
        return window.location.origin;
      } else {
        return (
          (config.public.rEnv === 'development' ? 'http://' : 'https://') +
          headers['host']
        );
      }
    };

    const localizedPath = (path: string): string => {
      let newPath: string = path.replace('default/', '');
      if (!newPath) {
        return '/';
      }

      if (path[0] === '/') {
        newPath = newPath.slice(1);
      }

      const pathLocale = getLocaleFromPath(`/${path}`);
      newPath = newPath.replace(/\/+$/, '');

      if (pathLocale === getDefaultLocale()) {
        newPath = newPath.slice(3);
      }

      // Remove country specfication
      const slugs = newPath.split('/');
      if ([0, 1].includes(slugs.indexOf('estonia'))) {
        slugs.splice(slugs.indexOf('estonia'), 1);
      }
      if ([0, 1].includes(slugs.indexOf('poland'))) {
        slugs.splice(slugs.indexOf('poland'), 1);
      }
      newPath = slugs.join('/');

      if (!pathLocale && i18n.locale.value !== getDefaultLocale()) {
        return `/${i18n.locale.value}/${newPath}`;
      }

      // storyblok specific root folder /home
      if (
        newPath.slice(newPath.length - 7) === `${i18n.locale.value}/home` ||
        newPath === 'home'
      ) {
        newPath = newPath.slice(0, -5);
      }
      return `/${newPath}`;
    };

    return {
      provide: {
        localizedPath,
        getLocaleFromPath,
        getCountry,
        getCountryIsEstonia,
        getDefaultLocale,
        getHost,
        getHostnameFromURL,
      },
    };
  },
});
