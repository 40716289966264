<template>
  <nav
    class="flex justify-center items-stretch lg:space-y-0"
    :class="mobile ? 'flex-col' : 'flex-row'"
  >
    <template v-if="mobile">
      <template v-for="menuItem in menuItems" :key="menuItem.id">
        <div>
          <!--    Menu item, which contains submenu      -->
          <template v-if="menuItem.component === 'NavigationItemNext'">
            <div
              class="text-base font-normal text-gray-600 hover:text-gray-900 cursor-pointer py-3 flex flex-row items-center justify-between gap-4"
              @click="openMenuItem = menuItem"
            >
              {{ menuItem.title }}
              <r-icon icon-name="chevron-right" />
            </div>
            <!--    Child menu - covers parent fully      -->
            <div
              v-if="openMenuItem === menuItem"
              class="absolute top-0 inset-x-0 transition transform origin-top-right bg-white w-full overflow-y-auto z-40 h-full"
            >
              <div
                class="text-base font-normal text-rendin-500 px-1 hover:text-gray-900 cursor-pointer px-6 py-6 flex flex-row items-center gap-4"
                @click="openMenuItem = null"
              >
                <r-icon icon-name="chevron-left" />
                <span>{{ menuItem.title }}</span>
              </div>
              <mega-menu
                :columns="menuItem.menuColumns"
                mobile
                @menu-item-click="(clickedItem) => onMenuItemClick(clickedItem)"
              />
            </div>
          </template>
          <!--    Menu item, which only has title and link     -->
          <template v-else-if="menuItem.component === 'NavigationItemSimple'">
            <r-storyblok-link
              class="text-base font-normal text-gray-600 hover:text-rendin-500 cursor-pointer py-3 flex flex-row items-center justify-between gap-4"
              :storyblok-link="menuItem.url"
              @click="onMenuItemClick(menuItem)"
            >
              {{ menuItem.title }}
            </r-storyblok-link>
          </template>
          <template v-else> ERROR: Unknown item! </template>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-for="menuItem in menuItems">
        <template v-if="menuItem.component === 'NavigationItemNext'">
          <r-dropdown
            :key="menuItem.id"
            :align="menuItem.align"
            open-on-hover
            :value="openMenuItem === menuItem"
            @input="
              (menuOpen) =>
                menuOpen ? (openMenuItem = menuItem) : (openMenuItem = null)
            "
          >
            <div
              class="text-sm xl:text-base font-normal text-gray-600 px-1 hover:text-rendin-500 cursor-pointer px-3 py-3"
            >
              <span>
                {{ menuItem.title }}
              </span>
              <r-icon icon-name="chevron-down" />
            </div>
            <template #dropdown>
              <mega-menu
                class="mt-5 p-4"
                :columns="menuItem.menuColumns"
                :mobile="mobile"
                @menu-item-click="(clickedItem) => onMenuItemClick(clickedItem)"
              />
            </template>
          </r-dropdown>
        </template>
        <!--    Menu item, which only has title and link     -->
        <template v-else-if="menuItem.component === 'NavigationItemSimple'">
          <r-storyblok-link
            :key="menuItem.id"
            class="text-sm xl:text-base font-normal text-gray-600 px-1 hover:text-gray-900 cursor-pointer px-3 py-3"
            :storyblok-link="menuItem.url"
            @click="onMenuItemClick(menuItem)"
          >
            {{ menuItem.title }}
          </r-storyblok-link>
        </template>
        <template v-else> ERROR: Unknown item! </template>
      </template>
    </template>
  </nav>
</template>
<script>
import { mapActions } from 'vuex';
import MegaMenu from '~/components/section/MegaMenu.vue';
export default {
  name: 'NavigationMenuSection',
  components: { MegaMenu },
  props: {
    /* List of NavigationItemNext or NavigationItemSimple blocks */
    menuItems: {
      type: Array,
      required: true,
    },
    mobile: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['menuItemClick'],
  data() {
    return {
      openMenuItem: null,
    };
  },
  methods: {
    ...mapActions({
      trackNavigationMenuItemClick: 'tracker/trackNavigationMenuItemClick',
    }),
    onMenuItemClick(menuItem) {
      if (menuItem && menuItem.url) {
        this.trackNavigationMenuItemClick({ url: menuItem.url.cached_url });
      }

      this.$emit('menuItemClick');
    },
  },
};
</script>
<style scoped>
.dropdown-max-height {
  max-height: calc(100vh - 8.5rem);
}

.item-active {
  @apply text-rendin-500;
}
</style>
